import { useIonRouter } from "@ionic/react";
import isBrowser from "../../utils/isBrowser";
import useOutlet from "../../hooks/useOutlet";
import { useLoadScript } from "@react-google-maps/api";
import { useCallback, useEffect, useRef, useState } from "react";

// Components
import MainFooter from "../components/MainFooter/MainFooter";
import DesktopMenu from "../components/DesktopMenu/DesktopMenu";
import MainHeader from "../../components/MainHeader/MainHeader";
import { TransitionLayout } from "../../contexts/TransitionContext";
import AppUrlListener from "../components/UrlOpenerProvider/UrlOpenerProvider";
import SimpleMahouLoader from "../../components/SimpleMahouLoader/SimpleMahouLoader";

// Style & Config
import "./Router.scss";
import { env } from "../../env";
import ROUTES from "../constants/routes";
import { initApp } from "../../services/init";
import { trackPage } from "../../services/applicationInsight";

const libraries: any = ["places"];

const Router = () => {
  const lastPathname = useRef<string | undefined>();

  const component = useOutlet();
  const { routeInfo, push, canGoBack, goBack } = useIonRouter();

  const [isLoading, setIsLoading] = useState(true);

  const handleReady = useCallback(() => setIsLoading(false), []);

  useLoadScript({
    googleMapsApiKey: env.GOOGLE_API_KEY || "",
    libraries,
  });

  // Browser back
  const handleBrowserBack = useCallback(
    () => push(window.location.pathname, "back", "pop"),
    [push],
  );

  // Hardware back
  const handleHardwareBack = useCallback(
    (ev: any) => {
      try {
        ev.detail.register(10, () => {
          if (canGoBack()) goBack();
          else push(lastPathname.current || ROUTES.HOME);
        });
      } catch (err) {
        console.warn("Hardware back problem", err);
      }
    },
    [canGoBack, goBack, push],
  );

  // Making track for a fluent navigation
  useEffect(() => {
    lastPathname.current = routeInfo.lastPathname;
  }, [routeInfo.lastPathname]);

  useEffect(() => {
    if (routeInfo.pathname !== lastPathname.current) {
      trackPage(routeInfo.pathname);
    }
  }, [routeInfo.pathname]);

  useEffect(() => {
    initApp(handleReady);

    if (isBrowser()) {
      window.addEventListener("popstate", handleBrowserBack);
      return () => window.removeEventListener("popstate", handleBrowserBack);
    } else {
      document.addEventListener("ionBackButton", handleHardwareBack);
      return () =>
        document.removeEventListener("ionBackButton", handleHardwareBack);
    }
  }, [handleBrowserBack, handleHardwareBack, handleReady]);

  return (
    <>
      <AppUrlListener />
      <div id="MainPage" className="pageClass">
        {isLoading && (
          <div className="firstLoader">
            <SimpleMahouLoader />
          </div>
        )}
        {!isLoading && (
          <>
            <MainHeader displayName hasProfileModal logoMediaQuery="md" />
            <div className="content">
              <TransitionLayout component={component} />
              <DesktopMenu />
            </div>
            <MainFooter />
          </>
        )}
      </div>
    </>
  );
};

export default Router;
