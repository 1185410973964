const ROUTES = {
  // Basic routes
  BASIC_HOME: "/",
  HOME: "/home",
  REDIRECT: "/callback",
  LOGOUT: "/logout",

  // User profile Routes
  USER: "/user",
  SETTINGS: "/settings",
  SETTINGS_M_DESCRIPTION: "/settings-mobile-description",
  SETTINGS_M_REFERENCES: "/settings-mobile-references",

  // POS Routes
  POS: "/pos",

  // Proposal Routes
  PROPOSALS: "/proposals",
  CREATE_PROPOSAL: "/create-proposal",
  EDIT_PROPOSAL: "/edit-proposal",
  PROPOSAL_EXTERNAL: "/proposals/ext",

  // Material Routes
  MATERIALS: "/materials",

  // Profile Routes
  PROFILE: "/profile",

  // Training
  TRAINING: "/training",

  // Create proposal sub-paths
  SELECT_POS: "/create-proposal/select-pos",
  RECOMMENDED_ASSORTMENT: "/create-proposal/recommended-assortment",
  COMMERCIAL_PROPOSAL: "/create-proposal/commercial-proposal",
  RECAP: "/create-proposal/recap",

  // DEV
  ICONS: "/dev/icons",
};

const notLoggedRoutes = [
  ROUTES.REDIRECT,
  ROUTES.LOGOUT,
  ROUTES.ICONS,
  ROUTES.PROPOSAL_EXTERNAL,
];
export const isLoggedRoutes = (path?: string) =>
  !notLoggedRoutes.includes(path || window.location.pathname);

export default ROUTES;
