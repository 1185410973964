import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { downloadMobileFile } from "../../utils/downloadMobileFile";
import { downloadDesktopFile } from "../../utils/downloadDesktopFile";
import { FileMimeType } from "../../components/ProposalModal/utils";
import { env } from "../../env";
import getLocationParams from "../../utils/getLocationParams";

export enum Status {
  LOADING = "ext_loading",
  ERROR_MISSING_INFO = "ext_missing_info",
  ERROR_BAD_INFO = "ext_wrong_info",
  DOWNLOAD_COMPLETE = "ext_downloaded",
}

export const handleProposalExternal = async () => {
  const params = getLocationParams();
  const { uuid, format } = params;

  if (!uuid || !format) {
    return Status.ERROR_MISSING_INFO;
  }
  try {
    const response = await axios.get(`${env.REST_URL}/proposal/getFileUrl`, {
      params: {
        uuid,
        format,
      },
    });
    const type = format === "pdf" ? FileMimeType.PDF : FileMimeType.PPTX;
    const splitUrl = response.data.split("/");
    const name = splitUrl[splitUrl.length - 1];

    if (Capacitor.isNativePlatform()) {
      await downloadMobileFile(response.data, name, undefined, type, false);
    } else {
      await downloadDesktopFile(response.data, name, type);
    }

    return Status.DOWNLOAD_COMPLETE;
  } catch (error) {
    return Status.ERROR_BAD_INFO;
  }
};
