import { useCallback, useEffect, useState } from "react";
import { useLocalize } from "@france/superelements/utils";
import useAppDispatch from "../../../../../../redux/hooks/useAppDispatch";
import useAppSelector from "../../../../../../redux/hooks/useAppSelector";

import { IonRange } from "@ionic/react";

import "./FilterVolumeRange.scss";
import { SetVolumeRange } from "../../../../../../redux/slices/posFilters";
import useMobileSize from "../../../../../../utils/commercialConditions/useMobileSize";

export const MIN_VOLUME_RANGE = 0;
export const MAX_VOLUME_RANGE = 1000;

const RANGE_VALUE = 5;
const MOBILE_RANGE_VALUE = 10;

const emptyState = { lower: MIN_VOLUME_RANGE, upper: MAX_VOLUME_RANGE };

const FilterVolumeRange: React.FC<unknown> = () => {
  const t = useLocalize();
  const dispatch = useAppDispatch();
  const isMobileSize = useMobileSize();

  const currentSelection = useAppSelector(
    (state) => state.posFilters.filters.volumeRange,
  );

  const [values, setValues] = useState(
    currentSelection
      ? {
          lower: currentSelection[0] || emptyState.lower,
          upper: currentSelection[1] || emptyState.upper,
        }
      : emptyState,
  );

  // Handlers
  const handleSelect = useCallback(() => {
    const newVolumeRange: [number, number] = [values.lower, values.upper];
    dispatch(SetVolumeRange({ volumeRange: newVolumeRange }));
  }, [values, dispatch]);

  const handleLowerInput = useCallback(
    (ev: any) =>
      setValues((prev) => {
        let value = Number(ev.target.value) || MIN_VOLUME_RANGE;

        if (value > MAX_VOLUME_RANGE) value = MAX_VOLUME_RANGE - 1;
        if (value < MIN_VOLUME_RANGE) value = MIN_VOLUME_RANGE;

        return {
          ...prev,
          lower: value,
        };
      }),
    [],
  );

  const handleUpperInput = useCallback((ev: any) => {
    setValues((prev) => {
      let value = Number(ev.target.value) || MAX_VOLUME_RANGE;

      if (value > MAX_VOLUME_RANGE) value = MAX_VOLUME_RANGE;
      if (value < MIN_VOLUME_RANGE) value = MIN_VOLUME_RANGE + 1;

      return {
        ...prev,
        upper: value,
      };
    });
  }, []);

  const handleRangeInput = useCallback((ev: any) => {
    const evValues = ev.detail.value;
    setValues(evValues);
  }, []);

  // Effects
  useEffect(() => {
    if (
      currentSelection &&
      values?.lower !== currentSelection[0] &&
      values?.upper !== currentSelection[1]
    ) {
      setValues({
        lower: currentSelection[0] || emptyState.lower,
        upper: currentSelection[1] || emptyState.upper,
      });
    }
    // eslint-disable-next-line
  }, [currentSelection]);

  useEffect(() => {
    const timer = setTimeout(handleSelect, 500);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [handleSelect, values]);

  return (
    <div className="Filter FilterVolumeRange">
      <h3>{t("volumeRangeFilter")}</h3>
      <div className="rangeBox">
        <div className="inputRange">
          <div className="inputBox">
            <input
              onChange={handleLowerInput}
              value={values.lower}
              type="number"
              min={MIN_VOLUME_RANGE}
              max={MAX_VOLUME_RANGE - 1}
            />
            <span>hL</span>
          </div>
          <span>-</span>
          <div className="inputBox">
            <input
              onChange={handleUpperInput}
              value={values.upper}
              type="number"
            />
            <span>hL</span>
          </div>
        </div>
        <IonRange
          min={MIN_VOLUME_RANGE}
          max={MAX_VOLUME_RANGE}
          value={values}
          dualKnobs={true}
          ticks={true}
          snaps={true}
          step={isMobileSize ? MOBILE_RANGE_VALUE : RANGE_VALUE}
          onIonInput={handleRangeInput}
        />
      </div>
    </div>
  );
};

export default FilterVolumeRange;
