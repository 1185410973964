const getLocationParams = () => {
  return window.location.search
    .slice(1)
    .split("&")
    .reduce((acc: any, item) => {
      const [key, ...values] = item.split("=");
      const value = values.join("=");

      acc[decodeURIComponent(key) as string] = decodeURIComponent(value);
      return acc;
    }, {});
};

export default getLocationParams;
