import { FC } from "react";
import pages from "./constants/lazyLoad";
import ROUTES from "./constants/routes";

/*
 ** All the main Routes;
 ** Please use this when redirecting.
 ** Example
 **   ❌ push('/path');
 **   ❌ push(`path/${id}`);
 **   ❌ window.location.href = '/path'
 **   ✅ push(ROUTES.PATH);
 **   ✅ push(`${ROUTES.PATH}/${id}`);
 **   ✅ window.location.href = ROUTES.PATH
 */

// Type
interface ConfigType {
  path: string;
  component: React.LazyExoticComponent<FC<any>>;
  exact?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  props?: { [key: string]: any };
}

// Routes
const config: ConfigType[] = [
  // Common / Essential
  {
    path: ROUTES.HOME,
    exact: true,
    component: pages.Home,
  },
  {
    path: ROUTES.PROFILE,
    exact: true,
    component: pages.Profile,
    noHeader: true,
  },
  {
    path: ROUTES.SETTINGS,
    exact: true,
    component: pages.Settings,
    noHeader: true,
  },
  {
    path: ROUTES.SETTINGS_M_DESCRIPTION,
    exact: true,
    component: pages.SettingsMobileDescription,
    noHeader: true,
  },
  {
    path: ROUTES.SETTINGS_M_REFERENCES,
    exact: true,
    component: pages.SettingsMobilePreferences,
    noHeader: true,
  },
  {
    path: ROUTES.TRAINING,
    exact: false,
    component: pages.Training,
    noHeader: true,
    noFooter: true,
  },
  {
    path: `${ROUTES.CREATE_PROPOSAL}/:pane`,
    exact: true,
    component: pages.PosCreateProposal,
    noHeader: true,
    noFooter: true,
  },

  // POS
  {
    path: ROUTES.POS,
    exact: true,
    component: pages.PosList,
    noHeader: true,
  },
  {
    path: `${ROUTES.POS}/:id`,
    exact: true,
    component: pages.PosDetail,
  },
  {
    path: `${ROUTES.POS}/:specific/:eid`,
    exact: true,
    noHeader: true,
    component: pages.PosDetailExternal,
  },

  // Materials
  {
    path: ROUTES.MATERIALS,
    exact: false,
    component: pages.Materials,
  },
  // DEV
  {
    path: ROUTES.ICONS,
    exact: false,
    component: pages.Icons,
    noHeader: true,
    noFooter: true,
  },
  // EXTERNAL PROPOSAL
  {
    path: ROUTES.PROPOSALS,
    exact: true,
    component: pages.Proposals,
  },
  {
    path: ROUTES.PROPOSAL_EXTERNAL,
    exact: false,
    component: pages.ProposalExternal,
    noHeader: true,
    noFooter: true,
  },
];

export const hideHeader = [
  ROUTES.LOGOUT,
  ROUTES.REDIRECT,
  ROUTES.PROFILE,
  ROUTES.PROPOSAL_EXTERNAL,
];

export default config;
